import React, { FunctionComponent } from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import IRoute from '../../../shared/models/interfaces/iroute';

interface NavigationItemProps {
    route: IRoute;
}

const NavigationItem: FunctionComponent<NavigationItemProps> = (props) => {
    const { route } = props;
    const navigate = useNavigate();
    const resolved = useResolvedPath(route.path ?? '');
    const match = useMatch({ path: resolved.pathname, end: false });

    const handleItemClick = () => {
        if (!route.path) return;
        navigate(route.path.replace('/*', ''), { replace: false });
    };

    return (
        <Menu.Item
            active={match !== null}
            as="a"
            className="nav-item"
            onClick={handleItemClick}
            style={{ color: 'white' }}
        >
            {route.name}
        </Menu.Item>
    );
};

export default NavigationItem;
